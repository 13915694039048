import React, {useEffect, useState} from 'react';
import {AppBar, Box, Button, Link, Toolbar, Tooltip, useScrollTrigger} from '@mui/material';
import {AiOutlineLeft} from 'react-icons/ai';
import {useNavigate} from 'react-router-dom';
import {Routes, Route} from 'react-router-dom';
import MixLink from './MixLink';

export default function HeaderBar(props) {
	const navigate = useNavigate();
	const [trigger, setIsTriggered] = useState(false);
	const [isHovered, setIsHovered] = useState(false);
	const scrollTrigger = useScrollTrigger({
		disableHysteresis: true,
		threshold: 0,
		target: window

	});

	useEffect(() => {
		handleSetTrigger({
			scroll: scrollTrigger
		})
	}, [scrollTrigger]);

	useEffect(() => {
		handleSetTrigger({
			hover: isHovered
		})
	}, [isHovered]);

	function handleSetTrigger({triggers}) {


	}


		
	
    return <AppBar
        color="secondary"
        onMouseEnter={() => {
        setIsHovered(true);
    }}
        onMouseLeave={() => {
        setIsHovered(false);
    }}
        sx={{
        backgroundColor: '#0007'
    }}
        className="app-bar">
        <Toolbar
            sx={{
            height: trigger
                ? '48px'
                : '64px',
            minHeight: trigger
                ? '48px'
                : '64px',
            transition: 'height .3s ease-in-out, min-height .3s ease-in-out', 
			'@media (min-width: 600px)': { height: trigger ? '48px' : '64px', minHeight: trigger ? '48px' : '64px', } }}>
            <Box
                display={'grid'}
                gridTemplateColumns={'1fr auto 1fr'}
                alignItems={'center'}
                gap={2}
                width={'100%'}>
                <Box>
                    <Routes>
                        <Route path="/" element={null}/>
                        <Route path="" element={null}/>
                        <Route
                            path="*"
                            element={
							<Button startIcon = { < AiOutlineLeft />
                        }
                        sx = {{ color: 'white', }}size = {
                            trigger
                                ? "small"
                                : "medium"
                        }
                        variant = "text" color = "secondary" onClick = {
                            () => navigate(-1)
                        } > Zurück </Button>
						}/>
                    </Routes>
                </Box>
                <Box display={'flex'} alignItems={'center'} justifyContent={'center'} gap={.3}>
                    <Box
                        sx={{
                        position: 'realtive', transform: 'scale(1.4)', transformOrigin: 'right center', zIndex: 3, '&:hover': { zIndex: 5, transform: 'scale(1.9)' } }}>
                        <Tooltip title="EPPINGEN.org Regionalportal" placement="bottom" arrow>
                            <Link href="https://www.eppingen.org" color={"#fff"}>
                                <img
                                    src="/org-weiss.png"
                                    style={{
                                    width: 'auto',
                                    height: trigger
                                        ? '24px'
                                        : '42px',
                                    display: 'block'
                                }}/>
                            </Link>
                        </Tooltip>
                    </Box>
                    <Box
                        sx={{
                        position: 'realtive', transform: 'scale(1)', zIndex: 3, transformOrigin: 'left center', '&:hover': { zIndex: 5, transform: 'scale(1.3)', } }}>
                        <Tooltip title="Jobzone Startseite" placement="bottom" arrow>
                            <MixLink
                                href="/"
                                onClick={() => {
                                window.scrollTo(0, 0);
                            }}>
                                <img
                                    src="/jobzone-quer.png"
                                    style={{
                                    width: 'auto',
                                    height: trigger
                                        ? '24px'
                                        : '42px',
                                    transition: 'all .3s ease-in-out',
                                    display: 'block'
                                }}/>
                            </MixLink>
                        </Tooltip>
                    </Box>
                </Box>
                <div></div>
            </Box>
        </Toolbar>
    </AppBar>
}