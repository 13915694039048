import { useState, useEffect } from 'react';


const useLocalStorage = (key, defaultValue) => {
	const [value, setValue] = useState(() => {
	  let currentValue;
  
	  try {
		currentValue = JSON.parse(
		  localStorage.getItem(key) || defaultValue
		);

		console.log('currentValue', currentValue);

		if(!currentValue?.__created) throw new Error('No __created property, go to default value');

		console.log('currentValue.__created', currentValue.__created);

		// Check if older than 1 minute:
		if(currentValue?.__created < new Date(new Date().getTime() - 60 * 1000)) {
			throw new Error('Older than 1 minute, go to default value');
		}

		console.log('currentValue.__modified', currentValue?.__modified);


		
	  } catch (error) {
		currentValue = {
			value: defaultValue
		}
	  }
  
	  return currentValue;
	});

	const setHandler = (newValue) => {
		setValue((prev) => {
			return {
				__created: prev?.__created || new Date(),
				__modified: prev?.__modified ? new Date() : null,
				value: newValue
			}
		});
	}
  
	useEffect(() => {
	  localStorage.setItem(key, JSON.stringify(value));
	}, [value, key]);
  
	return [value.value, setHandler];
  };
  
  export default useLocalStorage;