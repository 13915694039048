import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
	createBrowserRouter,
	RouterProvider,
  } from "react-router-dom";
import { Box, createTheme, ThemeProvider } from '@mui/material';
import MockApp from './MockApp';

const theme = createTheme({
	palette: {
		primary: {
			main: '#2a0240'
		}
	}
});

const router = createBrowserRouter([
{
	path: "*",
	element: <ThemeProvider theme={theme}><App/></ThemeProvider>,
}
]);



const root = ReactDOM.createRoot(document.getElementById('jobzone'));
root.render(
  <React.StrictMode>
	
    <RouterProvider router={router}>
	</RouterProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
