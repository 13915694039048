import { AppBar, Avatar, Badge, Box, Button, Chip, Container, Dialog, DialogContent, DialogTitle, Divider, Grid, Link, List, ListItem, ListItemAvatar, ListItemText, TextField, Toolbar, Tooltip, Typography } from "@mui/material";
import TopArbeitgeber from "./components/TopArbeitgeber";
import { FaCheckCircle, FaMapMarker } from "react-icons/fa";
import Carousel from "react-multi-carousel";
import { useEffect, useState } from "react";
import useResponsive from "./hooks/useResponsive";
import JobCard from "./components/JobCard";
import { Route, Router, Routes, useNavigate } from "react-router-dom";
import constants from "./hooks/useConstants";
import Skeleton from "react-loading-skeleton";
import { LiaMapMarkerSolid } from "react-icons/lia";
import Startseite from "./pages/Startseite";
import FullView from "./pages/FullView";
import { GiAlliedStar } from "react-icons/gi";
import JobDetail from "./pages/JobDetail";


import './App.css'
import { AiFillCiCircle, AiOutlineLeft } from "react-icons/ai";
import MixLink from "./components/MixLink";
import WaitingStartseite from "./pages/WaitingStartseite";


function MockApp() {
	const navigate = useNavigate();

  return (
    <Box>
		<AppBar color="secondary" sx={{
			backgroundColor: '#0007'
		}} className="app-bar">
			<Toolbar>
				<Box display={'grid'} gridTemplateColumns={'1fr auto 1fr'} alignItems={'center'} gap={2} width={'100%'}>
					<Box>
						<Routes>
							<Route path="/" element={null} />
							<Route path="*" element={<Button 
							
							startIcon={<AiOutlineLeft />}
							sx={{
								color: 'white',
							}} variant="text" color="secondary" onClick={() => navigate(-1)}>Zurück</Button>} />
						</Routes>
					</Box>
					<Box display={'flex'} alignItems={'center'} justifyContent={'center'} gap={.3}>
						<Box sx={{
							position: 'realtive',
							// make it bigger
							transform: 'scale(1.4)',
							transformOrigin: 'right center',
							zIndex: 3,
							'&:hover': {
								zIndex: 5,
								transform: 'scale(1.9)'
							}
						}}>
							<Tooltip
								title="EPPINGEN.org Regionalportal"
								placement="bottom"
								arrow
							>
								<Link href="https://www.eppingen.org" color={"#fff"}>
									<img src="/org-weiss.png" style={{ width: 'auto', height: '42px', display: 'block' }} />
								</Link>
							</Tooltip>
						</Box>
						<Box sx={{
							position: 'realtive',
							// make it bigger
							transform: 'scale(1)',
							zIndex: 3,
							transformOrigin: 'left center',
							'&:hover': {
								zIndex: 5,
								transform: 'scale(1.3)',
								// set transform origin to the left bottom corner

							}
						}}>
							<Tooltip
									title="Jobzone Startseite"
									placement="bottom"
									arrow
								>
								<MixLink href="/">
									<img src="/jobzone-quer.png" style={{ width: 'auto', height: '42px', display: 'block' }} />
								</MixLink>
							</Tooltip>
						</Box>
					</Box>
					<div></div>
				</Box>
			</Toolbar>
		</AppBar>
		<WaitingStartseite />

	</Box>
  );
}

export default MockApp;
