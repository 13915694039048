import { Box, Typography } from "@mui/material";

export default function Section(props) {
	const { children, title, ...rest } = props;
	return <Box {...rest} px={rest.px || {
		'xs': 2,
		'md': 3
	}}>
		{title ? <Typography variant="h3" gutterBottom>{title}</Typography> : null}
 		{children}
	</Box>
}