import { Box, Card, CardContent, CardHeader, CardMedia, Grid, IconButton, List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material"
import Section from "../Section"
import useResponsive from "../../hooks/useResponsive"
import Carousel from "react-multi-carousel";
import { useMemo, useState } from "react";
import { AiOutlineLoading } from "react-icons/ai";
import { FaCheckCircle } from "react-icons/fa";
import constants from "../../hooks/useConstants";
import useJobs from "../../hooks/useJobs";
import MixLink from "../MixLink";

export default function TippsSection(props) {
	const [bewerbungstipps, setBewerbungstipps] = useState(null);
	const responsive = useResponsive();
	const { jobzone } = useJobs();


	const shortTipps = useMemo(() => {
		let randomTipps = [];
		const tipps = constants.SHORT_TIPPS;
		for(let i = 0; i < 3; i++) {
			const randomIndex = Math.floor(Math.random() * tipps.length);
			randomTipps.push(tipps[randomIndex]);
		}
		return randomTipps;
	}, [bewerbungstipps]);

	return <Section my={7}>
			
		<Grid container spacing={2} direction="row"
			justifyContent="center"
			alignItems="stretch">
			<Grid item xs={12} md={8}>
				<Box color={'white'} p={0} borderRadius={3} sx={{
					backgroundImage: 'url(/backgrounds/Hintergrund_3.jpg)',
					backgroundSize: 'cover',
					height: '100%'
				}}>
					<Box pt={3} px={4}>
						<Typography variant="h4">Bewerbungstipps</Typography>
					</Box>

					<Grid container>
						
					<Grid item xs={12} md={5}>
							<Box height={'100%'} display={'flex'} alignItems={'end'} justifyContent={'center'} sx={{
								backgroundImage: 'url(/handsome_guy.png)',
								backgroundRepeat: 'no-repeat',
								backgroundPosition: 'bottom right',
								backgroundSize: 'contain',
								borderBottomRightRadius: 10
							}}>
							</Box>

						</Grid>
						<Grid item xs={12} md={7}>
							<Box pb={2} pt={3} pl={3}>
								{jobzone?.articles ? <Carousel responsive={responsive.one} partialVisbile={true} infinite={true}>
									{jobzone?.articles?.map((value, index) => {
										return <Box px={.5} height={'100%'}>
											<Box component={MixLink} href={value.link} target="_blank" sx={{
												textDecoration: 'none',
											}}>
										<Card sx={{bgcolor:'#000b',color:'#fff',height:'100%'}}>
										<CardMedia
											component="img"
											sx={{ 
												width: '100%',
												height: '180px',
												objectFit: 'cover',
												bgcolor:'#eee',
											 }}
											image={value.imagePath ? (
												`${constants.BASE_MEDIA_URL}${value.imagePath}`
											) : value.imageLink}
											alt={`Tipps zur Ausbildungsplatzsuche`}
										/>
										<CardContent sx={{gap:1}}>
											<Typography variant="h6">
												{value.title}
											</Typography>
											<Typography variant="body1">
												{value.description}
											</Typography>
										</CardContent>
									</Card>
											</Box>
									</Box>
									})}
								</Carousel> : null}
							</Box>
						</Grid>
					</Grid>
				</Box>
			</Grid>
			<Grid item xs={12} md={4}  sx={{
			}}>
				<Card sx={{
					background: 'url(/backgrounds/blue_synthwave_background.jpg) no-repeat center center fixed',
						backgroundSize: 'cover',
						height: '100%',
					
				}}>
					<Box p={1}>
					<Box bgcolor={'#e93a59aa'} borderRadius={2} sx={{
						background: 'url(/backgrounds/Hintergrund_blau.jpg)',
						backgroundSize: 'cover',
						backgroundRepeat: 'no-repeat',
						backgroundPosition: 'center',
						height: '100%',
						color:'#000',
					}}>
					<CardHeader title="Berufliche Zukunft im Fokus" subheader="Erfahre, wie du erfolgreich in die Arbeitswelt einsteigen kannst. Diese kurzen und prägnanten Tipps helfen dir, deine beruflichen Ziele zu erreichen, Fähigkeiten zu entwickeln und dich auf eine vielversprechende Karriere vorzubereiten." action={
						<IconButton onClick={() => setBewerbungstipps(Date.now())}>
							<AiOutlineLoading size={24} />
						</IconButton>
					} />
					<CardContent sx={{pt:0}}>
					<List dense>
						{shortTipps.map((value, index) => {
							return <ListItem>
								<ListItemIcon>
									<FaCheckCircle size={32} color={'#fff'} />
								</ListItemIcon>
								<ListItemText
									primary={value.Titel}
									secondary={value.Beschreibung}
								/>
							</ListItem>
						})}
					</List>
					</CardContent>
					</Box>
					</Box>
				</Card>
			</Grid>
		</Grid>
	</Section>
}