import { Box, Typography } from "@mui/material";
import Carousel from "react-multi-carousel";
import useResponsive from "../hooks/useResponsive";
import JobCard from "./JobCard";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";

export default function JobCarousel(props) {
	const { jobCluster, jobsById, jobMapKey, responsive: rs } = props;
	const responsive = useResponsive();

	return Object.keys(jobCluster).map((key) => {
		if(jobMapKey && jobMapKey !== key)
			return null;
		const jobArray = jobCluster[key];

		return <Box key={key} {...props.rest}>
			{!jobMapKey ? <Typography variant="h3"  pl={1} gutterBottom>Arbeiten mit {key}</Typography> : null}
			<Carousel 
				customLeftArrow={<Box 
					sx={{cursor:'pointer',position:'absolute',borderTopRightRadius:'100%',borderBottomRightRadius:'100%',background:'#0003'}}
					display={'flex'}
					py={2}
					pl={1}
					pr={2}
					justifyContent={'center'}
					alignItems={'center'}
				>
					<AiOutlineLeft size={'32'} />
				</Box>}
				customRightArrow={<Box 
					sx={{cursor:'pointer',position:'absolute',right:0,borderTopLeftRadius:'100%',borderBottomLeftRadius:'100%',background:'#0003'}}
					display={'flex'}
					py={2}
					pr={1}
					pl={2}
					justifyContent={'center'}
					alignItems={'center'}
				>
					<AiOutlineRight size={'32'} />
				</Box>}

				responsive={rs || responsive.small} 
				partialVisbile={true} 
				infinite={false}>
				{jobArray.map((jobKey) => {
					const job = jobsById[jobKey];
					return <JobCard key={jobKey} job={job} />
				})}
			</Carousel>

		</Box>
	})
}