import moment from "moment";
import 'moment/locale/de';
import constants from "../hooks/useConstants";
import { Avatar, Box, Chip, Divider, Typography } from "@mui/material";
import Skeleton from "react-loading-skeleton";
import { blueGrey } from "@mui/material/colors";
import { LiaMapMarkerSolid } from "react-icons/lia";
import { AiOutlineCalendar } from "react-icons/ai";
import { Link } from "react-router-dom";
import MixLink from "./MixLink";




export default function JobCard(props) {
    const { job,variant=false,hideImage=false,hideAccount=false } = props;
    const momento = moment;
    momento.locale('de');



    return (
       <Box 
	   component={MixLink}
	   href={`/job/${job.slug}`}
	   underline={'none'}
	   color={'#fff'}
	   		height={'100%'} 
			display={'flex'} 
			flexDirection={'column'} 
			justifyContent={'space-between'} 
			mx={1}>
				<Box flexGrow={1} display={'flex'} 
				sx={{border:`5px solid ${job?.corp_identity?.actionColor || '#eee'}`,backgroundColor:job?.corp_identity?.backgroundColor, color:job?.corp_identity?.textColor}}
				borderRadius={3} 
			flexDirection={'column'} 
			justifyContent={'space-between'}>
			<Box height={'100%'} display={'flex'} flexDirection={'column'} justifyContent={'space-between'}>
				{!hideAccount ? <Box display={'flex'} justifyContent={'start'} alignItems={'center'} gap={1} py={1} bgcolor={blueGrey[50]} sx={{borderTopRightRadius:5, borderTopLeftRadius:5}} px={1}>
					<Avatar src={`${constants.BASE_MEDIA_URL}${job?.account?.account_logo}`} alt={job?.account_name} sx={{width: 32, height: 32, borderRadius:2,bgcolor:'#fff'}} imgProps={{
						style: {
							objectFit: 'contain'
						}
					}} />
						<Typography variant="body2" component="div" className="mb-0" lineHeight={1.3} fontWeight={500} color={'#333'}>
							{job?.account?.name || <Skeleton/>}
						</Typography>
				</Box> : ''}
				<Divider />

				{job?.image && job?.display_variant == 'coloredBorder' && !hideImage ? <img src={`${constants.BASE_MEDIA_URL}${job?.image}`} style={{
					width: '100%',
					height: 'auto',
				}} />
								: null}

				<Box px={2} py={1.5} flexGrow={1} display={'flex'} flexDirection={'column'} justifyContent={'center'}>
					<Typography variant="subtitle1" fontWeight={'bold'} lineHeight={1.3} gutterBottom>
						{job?.name}
					</Typography>
					<Typography variant="subtitle2" lineHeight={1.3}>
						{job?.additional_info}
					</Typography>
				</Box>
			</Box>

			<Box px={1.5} py={1} fontSize={12}>
				{job?.employment_address ? (<Chip sx={{mr:.5}} size="small" icon={<LiaMapMarkerSolid />} label={job?.employment_address?.city}/>) : ''}
				<Chip size="small" icon={<AiOutlineCalendar/>} label={(job?.employment_start_type == 'instant' || !job?.employment_start_type) ?
					( <span> ab sofort</span>) : ( <span title={momento(job?.employment_start_date)?.format('d.M.Y')}> ab {momento(job?.employment_start_date)?.format('MMMM Y')}</span>) } />
			</Box>
				</Box>
	   </Box>
    )
}