import { Box, Divider, Typography } from "@mui/material";
import { AiOutlineLoading } from "react-icons/ai";

export default function Loader(props) {

	return <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
	<Box display={'flex'} justifyContent={'start'} alignItems={'center'} gap={3}>
		<AiOutlineLoading size="32" className="spin"/>
		<Divider orientation="vertical" flexItem />
		<Box>
			
			<Typography variant="body1" fontWeight={'bold'}>Lade Daten...</Typography>
		</Box>

	</Box>
</Box>
}