import axios from "axios";
import { useEffect, useState } from "react";
import constants from "./useConstants";

export default function useMoreJobs(props = {}) {
	const [moreJobs, setMoreJobs] = useState(null);
	const [isFetchingJobs, setIsFetchingJobs] = useState(true);

	function fetchMoreJobs(cluster = null) {
		setIsFetchingJobs(true);

		// Create URL Query String
		let queryString = cluster ? buildQueryString(cluster) : null;


		axios.get(`${constants.BASE_XHR_URL}api/v1/jobs/jobzone/more${queryString ? '?' + queryString : ''}`)
		.then(response => {
			setMoreJobs(response.data.jobOffersFiltered);
		})
		.finally(() => {
			setIsFetchingJobs(false);
		});
	}

	async function applyFilters(newCluster) {
		fetchMoreJobs(newCluster);
	}

	function buildQueryString(filter) {
		let queryStringArray = [];
		if(filter) {
			Object.keys(filter).map((key, i) => {
				queryStringArray.push(key + '=' + encodeURIComponent(filter[key]));
			});

		}

		return queryStringArray.join('&');
	}

	useEffect(() => {
		if(!moreJobs) {
			fetchMoreJobs();
		}
	}, []);

	return {
		moreJobs,
		isFetchingJobs,
		fetchMoreJobs,
		applyFilters
	}
}
