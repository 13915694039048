export default function useResponsive() {
	return {
		single: {
			all: {
				breakpoint: { max: 4000, min: 0 },
				items: 1,
				partialVisibilityGutter: 0
			}
		},
		one: {
			
			superLargeDesktop: {
				// the naming can be any, depends on you.
				breakpoint: { max: 4000, min: 3000 },
				items: 2,
				partialVisibilityGutter: 40
			},
			desktop: {
				breakpoint: { max: 3000, min: 1024 },
				items: 2,
				partialVisibilityGutter: 40
			},
			tablet: {
				breakpoint: { max: 1024, min: 464 },
				items: 2,
				partialVisibilityGutter: 40
			},
			mobile: {
				breakpoint: { max: 464, min: 0 },
				items: 1,
				partialVisibilityGutter: 10
			}
		},
		small: {
			superLargeDesktop: {
				// the naming can be any, depends on you.
				breakpoint: { max: 4000, min: 3000 },
				items: 5,
				partialVisibilityGutter: 40
			},
			desktop: {
				breakpoint: { max: 3000, min: 1024 },
				items: 4,
				partialVisibilityGutter: 40
			},
			tablet: {
				breakpoint: { max: 1024, min: 464 },
				items: 3,
				partialVisibilityGutter: 40
			},
			mobile: {
				breakpoint: { max: 464, min: 0 },
				items: 1,
				partialVisibilityGutter: 40
			}
		},
		jobs: {
			superLargeDesktop: {
				// the naming can be any, depends on you.
				breakpoint: { max: 4000, min: 3000 },
				items: 5,
				partialVisibilityGutter: 40
			},
			desktop: {
				breakpoint: { max: 3000, min: 1024 },
				items: 4,
				partialVisibilityGutter: 10
			},
			tablet: {
				breakpoint: { max: 1024, min: 464 },
				items: 4,
				partialVisibilityGutter: 40
			},
			mobile: {
				breakpoint: { max: 464, min: 0 },
				items: 3,
				partialVisibilityGutter: 40
			}
		},
		smaller: {
			any: {
				breakpoint: { max: 4000, min: 0 },
				items: 3,
				partialVisibilityGutter: 40
			}
		}
	}
}