import { Box, Button, Card, CardActions, CardContent, Chip, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { TypeAnimation } from "react-type-animation";
import React, { useMemo } from "react";
import JobCarousel from "./JobCarousel";
import useResponsive from "../hooks/useResponsive";
import MixLink from "./MixLink";
import TopArbeitgeber from "./TopArbeitgeber";


export default function JobsSection(props) {
	const { children, section, jobs, topArbeitgeber, ...rest } = props;
	const responsive = useResponsive();

	const theme = useTheme();
	const mobileUp = useMediaQuery(theme.breakpoints.up('md'));

	let background = section.backgrounds[0];
	// take random person
	let person = useMemo(() => {
		return section.persons[Math.floor(Math.random() * section.persons.length)];
	}, [section.persons]);
	let colorDark = true;
	let chats = section.chats;

	let header = section.titles[0];

	const [tipOpen, setOpenGesundheitTipps] = React.useState(null);

	
	const handleOpenTip = (tipp) => {
		let newTipp = {
			...tipp
		};
		let chats = newTipp.chats;
		// select random chat
		const randomIndex = Math.floor(Math.random() * chats.length);
		const randomChat = chats[randomIndex];
		newTipp.chat = randomChat;

		console.log(newTipp);

		setOpenGesundheitTipps(newTipp);

	}

	const GridPersonElement = <Grid item xs={12} md={4} lg={3} sx={{
		display: 'flex',
		alignItems: 'end',
		justifyContent: 'end',
		backgroundImage: `url(${person})`,
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'bottom center',
		backgroundSize: 'contain',
		minHeight: '500px',
	}}>
		<Box px={1} pb={1}>
			{tipOpen ? <Card variant="outlined" key={'tipp-' + tipOpen}>
				{tipOpen?.chat ? <CardContent key={'tipp-content-' + tipOpen.chat}><TypeAnimation
					sequence={[
						tipOpen.chat,
						700
					]}
					/></CardContent> : ''}
				<CardActions>
					<Button size="small" onClick={() => { setOpenGesundheitTipps(null) }}>Schließen</Button>
				</CardActions>
			</Card> : ''}
			{chats && chats.map((value, index) => {
				return <Chip label={value.Name} size="large" color="primary" sx={{m:.3}} onClick={() => { handleOpenTip(value) }} />
			})}
			
			
		</Box>
	</Grid>


	return <Box {...rest} sx={{
		background: `url(${background})`,
		backgroundSize: 'cover',
		backgroundPosition: 'center',
		backgroundRepeat: 'no-repeat',
		color: colorDark ? 'white' : 'black',
		pt: 2,
	}}
	id={section.key}>

<Box px={{
	xs: 2,
	md: 7	
}}>
{header.title ? <Box pt={section.layout == "right" ? 0 :0} pl={section.layout == "right" ? 7 : 0}>
					<MixLink href={`/alle/${section.key}`} className={'text-cutout-container'}>
						<Typography 
							variant={mobileUp ? "h5" : "h6"}
							fontWeight={'bold'}
							textTransform={'uppercase'}
							bgcolor={'#2a0240'}
							display={'inline-block'}
							color={'#fff'}
							className="text-cutout-w"
						>
							#{section?.name}
						</Typography>
					</MixLink>

					<Typography variant={mobileUp ? 'h2' : 'h5'} maxWidth={800}>{header.title}</Typography>
				{header.subheader ? <Typography variant="subtitle1" mb={5}>
					{header.subheader}
				</Typography> : null}
				</Box> : null}
</Box>

		<Grid container spacing={0}>
			{!section.layout ? GridPersonElement : null}
			<Grid item xs={12} md={8} lg={9}>
				
			<Box pb={3}>
				

				<JobCarousel
					responsive={responsive.small}
					jobsById={jobs.list}
					jobCluster={jobs.cluster}
					jobMapKey={section.key}
					/>

				
				<Box mt={5}>
					<Typography variant="caption" fontWeight={'bold'}>Top Arbeitgeber #{section.name}</Typography>
					{topArbeitgeber ? <TopArbeitgeber
						height={70}
						arbeitgeber={topArbeitgeber}
						sectionKey={section.key}
						hideKey={true}
						/> : null}
				</Box>

				
			</Box>

			</Grid>
			{section.layout == "right" ? GridPersonElement : null}
		</Grid>

		{children}
	</Box>
}