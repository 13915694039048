import React, { useEffect, useRef, useState } from 'react';
import { Box, Button, Container, Dialog, DialogTitle, Divider, Grid, Typography, Badge, Avatar, Tooltip, Card, AppBar, useScrollTrigger, Slide, Toolbar, Link, CardContent, CardHeader, IconButton, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText } from '@mui/material';
import constants from '../hooks/useConstants';
import { GiAlliedStar, GiStaryu } from 'react-icons/gi';
import { LiaMapMarkerSolid } from 'react-icons/lia';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { AiOutlineClose, AiOutlineLeft } from 'react-icons/ai';
import MixLink from '../components/MixLink';
import { BsAt, BsClipboard, BsGeoAlt, BsLink45Deg, BsPhone, BsTelephone, BsWhatsapp } from 'react-icons/bs';
import Mock from '../components/Mock';
import useOutsideClick from '../hooks/useOutsideClick';

export default function JobDetail(props) {
	const [job, setJob] = useState(null);
	const [jetztBewerben, setJetztBewerbenReal] = useState(false);
	const jobSlug = useParams().slug;

	const navigate = useNavigate();

	const jetztBewerbenRef = useRef(null);

	const setJetztBewerben = (value) => {
		jetztBewerbenRef.current.scrollIntoView({
			behavior: 'smooth',
			// offset top 48px
			block: 'start',
			inline: 'nearest',
		});

		setJetztBewerbenReal(value);
	}

	useEffect(() => {
		if(!jetztBewerbenRef?.current) return;

		
		
		const outsideClick = async (evt) => {
			let targetEl = evt.target; // clicked element      
			do {
				if(targetEl == jetztBewerbenRef.current) {
				}
				// Go up the DOM
				targetEl = targetEl.parentNode;
			} while (targetEl);
			// This is a click outside.    
			
			

				setJetztBewerben((prev) => {
					if(prev) {
						return false;
					}
					return prev;
				})
		}

		if(jetztBewerben) {
			setTimeout(() => {
				window.document.addEventListener('click', outsideClick);
			}, 100);
		} else {
			window.document.removeEventListener('click', outsideClick);
		}
			
		return () => {
			if(jetztBewerben)
				window.document.removeEventListener('click', outsideClick);
		}


	}, [jetztBewerbenRef, jetztBewerben]);

				
	



	useEffect(() => {
		fetch(`${constants.BASE_XHR_URL}api/v1/jobs/${jobSlug}`)
		.then(response => response.json())
		.then(data => {
			setJob(data);
		})
		.catch((error) => {
			console.error('Error:', error);
		})
		.finally(() => {
			console.log('Done');
		});
	}	, [jobSlug]);

	



	return <Box>
	<Dialog open={true} onClose={() => {}} scroll="body" maxWidth={'md'} fullWidth={true} sx={{
		'.MuiDialog-paper': {
			background: 'none'
			//border:`5px solid ${job?.corp_identity?.actionColor}`
		}
	}}
		slotProps={{
			backdrop: {
				sx: {
					backgroundColor: '#240d0add',
					backgroundImage: 'url(/jobzone.svg)',
					backgroundRepeat: 'no-repeat',
					backgroundPosition: 'right bottom',
					backgroundSize: 'contain',
					backgroundAttachment: 'fixed',
				}
			}
		}}
		fullScreen
	>
		
			<AppBar position='fixed' sx={{
				bgcolor: '#0007',
				py:0,
				maxHeight: 42,
				'.MuiToolbar-root': {
					py:0,
					maxHeight: 42,
					minHeight: 42,
				}
			}}>
			<Toolbar sx={{py:0,maxHeight:42}}>
				<Box display={'grid'} gridTemplateColumns={'1fr auto 1fr'} alignItems={'center'} gap={2} width={'100%'}>
					<Box>
						<Button 
							component={MixLink}
							href='/'
							
							startIcon={<AiOutlineClose />}
							sx={{
								color: '#fff7',
							}} size={"small"} variant="text" color="secondary" onClick={() => navigate(-1)}>Zurück</Button>
					</Box>
					<Box display={'flex'} alignItems={'center'} justifyContent={'center'} gap={.3}>
						<Box sx={{
							position: 'realtive',
							// make it bigger
							transformOrigin: 'right center',
							zIndex: 3,
							'&:hover': {
								zIndex: 5,
								transform: 'scale(1.4)'
							}
						}}>
							<Tooltip
								title="EPPINGEN.org Regionalportal"
								placement="bottom"
								arrow
							>
								<Link href="https://www.eppingen.org" color={"#fff"}>
									<img src="/org-weiss.png" style={{ width: 'auto', height: '32px'
										, display: 'block' }} />
								</Link>
							</Tooltip>
						</Box>
						<Box sx={{
							position: 'realtive',
							// make it bigger
							zIndex: 3,
							transformOrigin: 'left center',
							'&:hover': {
								zIndex: 5,
								transform: 'scale(1.1)',
								// set transform origin to the left bottom corner

							}
						}}>
							<Tooltip
									title="Jobzone Startseite"
									placement="bottom"
									arrow
								>
								<MixLink href="/" onClick={() => {
									window.scrollTo(0, 0);
								}}>
									<img src="/jobzone-quer.png" style={{ width: 'auto', height: '32px', transition:'all .3s ease-in-out',
										display: 'block' }} />
								</MixLink>
							</Tooltip>
						</Box>
					</Box>
					<div></div>
				</Box>
			</Toolbar>
			</AppBar>
		<Box px={{
			xs: 0,
			md: 2,
			
		}} mt={7}>
			<Grid container spacing={2}>
				<Grid item xs={12} md={8} lg={9}>
		<Container maxWidth={'sm'} sx={{
			marginRight: '0'
		}}>
		<Card sx={{
			border:`5px solid ${job?.corp_identity?.actionColor}`,
		}}
		variant='outlined'>
				<DialogTitle sx={{bgcolor:'#eee',p:2}}>
			<Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
				<Box display={'flex'} justifyContent={'start'} alignItems={'center'} gap={1.5}>
					<Badge
						invisible={job?.account?.top_employer ? false : true}
					sx={{
						// no padding for badgeContent
						'& .MuiBadge-badge': {
							padding: 0
						}
					}} badgeContent={<Tooltip title="TopArbeitgeber"><Typography variant="inherit"><GiStaryu size={16} /></Typography></Tooltip>} color="primary">
						<Avatar src={`${constants.BASE_MEDIA_URL}${job?.account?.account_logo}`} alt={job?.account_name} sx={{width: 50, height: 50, borderRadius:2,bgcolor:'#fff',
							boxShadow: '0 0 7px #3333'
					}}
							imgProps={{
								style: {
									objectFit: 'contain'
								}
							}}
						
						/>
						</Badge>
					<Typography variant="body2" component="div" className="mb-0" lineHeight={1.3} fontWeight={500} color={'#333'}>
						{job?.account?.name || <Skeleton width={150}/>}
					</Typography>
				</Box>
				<Button variant={jetztBewerben ? 'outlined' : 'contained'}
					 size="large" onClick={() => {
					setJetztBewerben(!jetztBewerben);
				}} disableElevation color="primary">Jetzt bewerben</Button>
			</Box>
		</DialogTitle>
					<Divider />
		{job?.image ? <img src={`${constants.BASE_MEDIA_URL}${job?.image}`} style={{ width: '100%', height: 'auto',display:'block' }} />
					: null}
					<Divider />
		<Box sx={
			{backgroundColor:job?.corp_identity?.backgroundColor, color:job?.corp_identity?.textColor}
		}>
			<Box p={3}>
				<Typography variant="h5" gutterBottom fontWeight={'bold'}>{job?.name}</Typography>
				<Typography variant="body1" gutterBottom>{job?.additional_info}</Typography>
				<Box display={'flex'} alignItems={'center'} gap={.5}>
					<LiaMapMarkerSolid size="16" />
					<Typography variant="body2">
						{job?.employment_address?.city}
					</Typography>
				</Box>
			</Box>
		</Box>
		<Divider />
		<Box p={3}>
			<Box dangerouslySetInnerHTML={{__html: job?.employment_description}}></Box>
		</Box>
		</Card>
		<Box my={2}>
			<Button variant={jetztBewerben ? 'outlined' : 'contained'} onClick={() => {
				setJetztBewerben(!jetztBewerben);
			}} disableElevation color="primary" fullWidth>Jetzt bewerben</Button>
		</Box>
		</Container>
				</Grid>
				<Grid item xs={12} md={4} lg={3}>
					<Container sx={{ marginLeft: 0 }}>
					<Box ref={jetztBewerbenRef} onClick={
						() => {
							setJetztBewerben(true);
						}
					} className={'' + (jetztBewerben ? 'background-splash' : 'background-splash-base')}
						sx={{
							// imitate Card w/o background
							backgroundColor: '#fff',
							borderRadius: 2,
							border: '1px solid #4b0471',
						}}>
						<CardHeader
							subheader={<Box><Typography>
								<Mock>So kannst du dich bewerben</Mock>
							</Typography>
							{job?.contact_person_name ? <Box  mt={1} display={'flex'} justifyContent={'space-between'}>
							<Box>
								<Typography variant="caption"><Mock>Ihr Ansprechpartner</Mock></Typography>
								<Typography variant={'subtitle1'}>
									<Mock>{job?.contact_person_name}</Mock>
								</Typography>
							</Box>
							<Box>
								{job?.apply_email ? <IconButton>
									<a href={`mailto:${job?.apply_email}`}>
										<BsAt size={24} />
									</a>
								</IconButton> : null}
								{job?.apply_phone ? <IconButton>
									<a href={`tel:${job?.apply_phone}`}>
										<BsTelephone size={24} />
									</a>
								</IconButton> : null}
							</Box>
						</Box> : null}
							</Box>}
							title="Bewerben"
						/>
						
						<List component={'div'}>
					{job?.apply_website ?<ListItem component='a' alignItems="flex" href={job.apply_website} target="_blank"  sx={{my:1,
						backgroundColor: '#fff1',
						color: '#000',
					}}>
								
								<ListItemIcon>
									<Mock>
									<BsLink45Deg size={45} />
									</Mock>
								</ListItemIcon>
								<ListItemText primary={<Typography color={
										jetztBewerben ? '#fffa' : '#000'
									}>
										<Mock
											>Via 
												{job?.apply_website?.startsWith('https://www.eingestellt-online.de') ? <span><img src="https://www.eingestellt-online.de/wp-content/uploads/2022/12/eingestellt-logo.svg" style={{width:'auto',height:'19px'}}/></span> : 'Webseite'} bewerben
										</Mock>
									</Typography>} secondary={<Typography title={job?.apply_website} variant="subtitle2" sx={{
									textOverflow: 'ellipsis',
									overflow: 'hidden',
									whiteSpace: 'nowrap',
								}}>
									<Mock>{job.apply_website}</Mock>
								</Typography>} />
								{/* <ListItemSecondaryAction>
									<IconButton edge="end" aria-label="copy">
										copy
									</IconButton>
								</ListItemSecondaryAction> */}
							</ListItem>:null}

												{job?.apply_whatsapp ? <ListItem component='a' alignItems="flex" href={job.apply_whatsapp}>
													<ListItemIcon sx={{color: '#4caf50'}}>
														<Mock>
															<BsWhatsapp size={45} />
														</Mock>
													</ListItemIcon>
													<ListItemText primary={<Typography><Mock>Via WhatsApp bewerben</Mock></Typography>} secondary={<Typography><Mock>{job.apply_whatsapp}</Mock></Typography>} />
												</ListItem>:null}

												{job?.apply_email ? <ListItem component='a' alignItems="flex" href={`mailto:${job.apply_email}`}>
													<ListItemIcon>
														<Mock>
															<BsAt size={45} />
														</Mock>
													</ListItemIcon>
													<ListItemText primary={<Typography><Mock>Via E-Mail bewerben</Mock></Typography>} secondary={<Typography><Mock>{job.apply_email}</Mock></Typography>} />
													{/* <ListItemSecondaryAction>
														<IconButton edge="end" aria-label="copy">
						copy todo								</IconButton>
													</ListItemSecondaryAction> */}
												</ListItem>:null}

												

												{job?.apply_phone? <ListItem component='a' alignItems="flex" href={`tel:${job.apply_phone}`}>
														<ListItemIcon>
															<Mock>
																<BsPhone size={45} />
															</Mock>
														</ListItemIcon>
														<ListItemText primary={<Typography><Mock>Via Telefon bewerben</Mock></Typography>} secondary={<Typography><Mock>{job.apply_phone}</Mock></Typography>} />
														{/* <ListItemSecondaryAction>
															<IconButton edge="end" aria-label="copy">
						copy todo									</IconButton>
														</ListItemSecondaryAction> */}
													</ListItem> :null}

												{job?.apply_address?<ListItem alignItems="flex">
														<ListItemIcon>
															<Mock>
																<BsGeoAlt size={45} />
															</Mock>
														</ListItemIcon>
														<ListItemText primary={<Typography><Mock>Bewerbung richten an</Mock></Typography>} secondary={<Box>
															<Typography variant="subtitle1" fontWeight={'bold'} component="div"><Mock>{job?.account?.name || <Skeleton count={1}/>}</Mock></Typography>
															{job?.contact_person_name ? <Typography variant="subtitle2" component="div"><Mock>{job?.contact_person_name}</Mock></Typography> : null}

															<p><Mock>{job?.apply_address?.street1}<br/>
													  		{job?.apply_address?.zip} {job?.apply_address?.city}</Mock></p>
														</Box>} />
														{/* <ListItemSecondaryAction>
															<IconButton edge="end" aria-label="copy">
						copy to do									</IconButton>
														</ListItemSecondaryAction> */}
													</ListItem>:null}


					</List>
					</Box>
					</Container>
				</Grid>
			</Grid>
		</Box>



		

	</Dialog>
</Box>
}