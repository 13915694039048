import { Avatar, Badge, Box, Button, Card, CardActions, CardContent, CardHeader, CardMedia, Chip, Container, Dialog, DialogContent, DialogTitle, Divider, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemButton, ListItemIcon, ListItemText, Popover, SpeedDial, SpeedDialAction, Tab, Tabs, TextField, Tooltip, Typography } from "@mui/material";
import TopArbeitgeber from "../components/TopArbeitgeber";
import JobCard from "../components/JobCard";
import Carousel from "react-multi-carousel";
import { useEffect, useMemo, useState } from "react";
import useResponsive from "../hooks/useResponsive";
import { blue, blueGrey, orange, purple } from "@mui/material/colors";
import { FaCheckCircle } from "react-icons/fa";
import MixLink from "../components/MixLink";
import useJobs from "../hooks/useJobs";
import { AiOutlineClose, AiOutlineLoading, AiOutlineRight } from "react-icons/ai";
import JobCarousel from "../components/JobCarousel";
import constants from "../hooks/useConstants";
import { GiStaryu } from "react-icons/gi";
import Loader from "../components/Loader";
import Section from "../components/Section";
import { useNavigate } from "react-router-dom";
import { TypeAnimation } from "react-type-animation";
import JobSection from "../components/JobSection";
import JobsSection from "../components/JobsSection";
import TippsSection from "../components/sections/TippsSection";
import TopStarbeitgeber from "../components/TopStarbeitgeber";
import JobzoneHelperNavigator from "../components/JobzoneHelperNavigator";
import LoaderInitial from "../components/LoaderInitial";
import HeroSection from "../components/sections/HeroSection";

const gesundheitswesen = [];
 
 


export default function Startseite(props) {
	const {jobzone, isFetchingJobzone, fetchJobzone} = useJobs();
	const responsive = useResponsive();
	const navigate = useNavigate();

	

	const seitenSektionen = useMemo(() => {
		if(!jobzone || !jobzone.sections) return null;

		// Jobzonen
		let alleZonen = jobzone?.sections.map((value, index) => {
			return {
				zone: <Box my={0}>
					<JobsSection
						topArbeitgeber={jobzone.topArbeitgeber}
						section={value}
						jobs={jobzone.jobOffers}
						/>
				</Box>,
				order: value.order
			}
		})

		// Breaker - Sections
		let breakerSections = [];

		// Breaker - Gesundheitstipps
		breakerSections.push({
			zone: <TippsSection />,
			order: 999
		});



		// Werbeblöcke
		console.log('RUNNING_AD', jobzone?.formatAds?.length);
		jobzone?.formatAds?.map((value, i) => {
			console.log('RUNNING ADS', i);
			alleZonen.push({
				zone: <Section className="panorama_ad" key={'panorama_ad-' + i}>
					<Container maxWidth={'lg'}>
						<Box textAlign={'end'} fontSize={11} color={'#fff'}>
							Werbung
						</Box>
						<MixLink href={value.linkTo} target="_blank">
							<img src={`${constants.BASE_MEDIA_URL}${value.image}`} alt={value.name}
							width={200}
							height={100}
							style={{
								width: '100%',
								height: 'auto',
								display: 'block',
								borderRadius: 10
							}} />
						</MixLink>
					</Container>
				</Section>,
				order: value.order
			})
			
		})

		
		// Breaker - Gesundheitstipps
		alleZonen.push({
			zone: <TippsSection />,
			order: 999
		});

		// random order
		alleZonen.sort((a, b) => { return b.order - a.order });



		return alleZonen;
	}, [jobzone]);

	




	if(isFetchingJobzone) return <LoaderInitial />;

	
	return <Box>
		
		<HeroSection jobzone={jobzone} />


		<Box key={'full-startseite'}>
	

		{/* Zufällige Top Arbeitgeber */}
		<Box  className={'section-large-background'}>
		<Section py={5}>
			<TopArbeitgeber hideKey={true} arbeitgeber={
				jobzone?.topArbeitgeber
			} />
		</Section>

		{/* Neuste Stellen & Übersicht */}
		<Section mb={11}>
			
		<Grid container spacing={2} direction="row"
  justifyContent="center"
  alignItems="stretch">
				<Grid item xs={12} md={6}>
						
					<Card  sx={{
						background: '#e4e5e9',
						height: '100%',

					}}>
						<JobzoneHelperNavigator />
					</Card>
				</Grid>
				<Grid item xs={12} md={6}  sx={{
				}}>
					
					<Box className={'glow'}>
					<Box bgcolor={purple[500]} color={'white'} p={0} borderRadius={3} sx={{
						backgroundImage: 'url(/backgrounds/Hintergrund_2.jpg)',
						backgroundSize: 'cover',
					}}>
						<Grid container>
							<Grid item xs={12} md={7}>
								<Box pb={2} pt={3} pl={3}>
									<Typography variant="h4" gutterBottom>Brandneue Stellen</Typography>
									<List dense>
										{Object.values(jobzone.jobOffers.list).map((value, index) => {
											// exit if index is greater than 3
											if(index > 3) return null;
											const job = value;
											return <ListItemButton key={'job-' + index} component={MixLink} href={`/job/${job.slug}`}>
												<ListItemAvatar>
													<Avatar src={`${constants.BASE_MEDIA_URL}${job?.account?.account_logo}`} alt={job?.account_name} sx={{width: 42, height: 42, borderRadius:2,bgcolor:'#fffa'}}
														imgProps={{
															style: {
																objectFit: 'contain'
															}
														}}
													
													/>
												</ListItemAvatar>
												<ListItemText
													primary={job.name}
													secondary={job.additional_info}
												/>
											</ListItemButton>
										})}
									</List>
								</Box>
							</Grid>
							<Grid item xs={12} md={5}>
								<Box height={'100%'} display={'flex'} alignItems={'end'} justifyContent={'center'} sx={{
									backgroundImage: 'url(guy_1.png)',
									backgroundRepeat: 'no-repeat',
									backgroundPosition: 'bottom right',
									backgroundSize: 'contain',
									borderBottomRightRadius: 10
								}}>
									<Button variant="contained" onClick={() => {
										navigate('/alle');
									}} color="primary" size="large" sx={{mb:3}}>Alle Stellen</Button>
								</Box>

							</Grid>
						</Grid>
					</Box>
					</Box>
					
				</Grid>
			</Grid>
		</Section>

		</Box>

		{/* Beginn aller Listen */}
		{seitenSektionen.map((value, index) => {
			return value.zone;
		})}

		</Box>

		<Box sx={{
			background: 'url(/backgrounds/Hintergrund_3.jpg)',
			backgroundSize: 'cover',
			backgroundPosition: 'center',
		}}>
			<Box  sx={{
				backgroundImage: 'url(/female-alle-trim.png)',
				backgroundRepeat: 'no-repeat',
				backgroundPosition: 'bottom center',
				backgroundSize: 'contain',
				aspectRatio: '1608 / 863',
			}}
				width={'100%'}
				display={'flex'}
				alignItems={'center'}
				justifyContent={'end'}
				flexDirection={'column'}
				maxHeight={863}
			>
				<Box className="text-cutout-container" mb={5} mt={20} py={2} pt={1.5}>
					<Box className="text-cutout-w" fontSize={{
						xs: 30,
						sm: 40,
						md: 60,
						lg: 70
					}} lineHeight={1}>
						#DANKESCHÖN
					</Box>
				</Box>
			</Box>
		</Box>



	</Box>
}