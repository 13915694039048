import { Box, Button, Card, CardContent, CardHeader, CardMedia, Dialog, Divider, Grid, List, ListItem, ListItemButton, ListItemIcon, ListItemText, ListSubheader, Popover, Tab, Tabs, Typography } from "@mui/material";
import constants from "../hooks/useConstants";
import { useEffect, useState } from "react";
import { GiStaryu } from "react-icons/gi";
import { AiOutlineLink } from "react-icons/ai";
import { LiaMapMarkerSolid } from "react-icons/lia";
import axios from "axios";
import Carousel from "react-multi-carousel";
import useResponsive from "../hooks/useResponsive";
import JobCard from "./JobCard";
import { purple } from "@mui/material/colors";



export default function TopArbeitgeberProfil(props) {
	const { arbeitgeber, height = 150 } = props;
	const [open, setOpen] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);
	const [ openTab, setOpenTab ] = useState(0);
	const responsive = useResponsive();

	const [ arbeitgeberJobs, setArbeitgeberJobs ] = useState(null);

	function handleClick(event) {
		setAnchorEl(event.currentTarget);
		setOpen(!open);
	}

	function handleTabChange(event, newValue) {
		setOpenTab(newValue);
	}


	return <Box  display={'flex'} justifyContent={'space-between'} alignItems={'center'} gap={1} flexDirection={'column'} height={'100%'} my={3}>
		<Box onClick={handleClick} sx={{cursor:'pointer'}}  height={height + 'px'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
			<img src={`${constants.BASE_MEDIA_URL}${arbeitgeber.account_logo}`} style={{
				height: height + 'px',
				width: '100%',
				maxWidth: '250px',
				objectFit: 'contain',
			}} />
		</Box>
		<Popover open={open} anchorEl={anchorEl} onClose={() => {
			setOpen(false);
		}}>
			<Card sx={{ display: 'flex'}}>
				<CardMedia
					component="img"
					sx={{ 
						width: 151,
						objectFit: 'contain',
						bgcolor:'#eee',
						p: 2
					 }}
					image={`${constants.BASE_MEDIA_URL}${arbeitgeber.account_logo}`}
					alt={arbeitgeber.name}
				/>
				<Box maxWidth={600} width={'100%'} minWidth={400}>
				<CardHeader title={arbeitgeber.name} 
					subheader={<Box display={'flex'} justifyContent={'flex-start'} alignItems={'center'} gap={1}>
							<Box display={'flex'} justifyContent={'center'} alignItems={'center'} gap={.5}>
								<LiaMapMarkerSolid size={16} />
								<Typography variant="caption" fontWeight={'bold'}>{arbeitgeber.billing_address.city}</Typography>
							</Box>
						</Box>}
					/>
				<Divider />
				<Tabs value={openTab} variant="fullWidth" onChange={handleTabChange} aria-label="basic tabs example">
					<Tab label="Details" value={0} />
					<Tab label="Über uns" value={1} />
					<Tab label="Jobs" value={2} />
				</Tabs>
				 <Divider />

				<Box hidden={openTab !== 0}>
					<List>
						<ListSubheader disableSticky>Links</ListSubheader>
						<Divider />
						<ListItemButton href={arbeitgeber.top_employer} target="_blank" sx={{bgcolor:purple[50]}}>
							<ListItemIcon>
								<GiStaryu size={32} color={purple[500]} />
							</ListItemIcon>
							<ListItemText primary={arbeitgeber.name + ' als Arbeitgeber'} secondary={
								"Arbeitgeberprofil auf EPPINGEN.org"
							} />
						</ListItemButton>
						<Divider />
						<ListItemButton href={arbeitgeber.account_website} target="_blank">
							<ListItemIcon>
								<AiOutlineLink size={32} />
							</ListItemIcon>
							<ListItemText primary={"Zur Webseite"} secondary={
								String(arbeitgeber.account_website).startsWith('http') ? arbeitgeber.account_website.match(/(?:\w+\.)+\w+/)[0] : ''
							} />
						</ListItemButton>
					</List>
				</Box>
				
				<Box hidden={openTab !== 1}>
					<CardContent dangerouslySetInnerHTML={{__html: arbeitgeber.description}}>
					</CardContent>
				</Box>

				<Box hidden={openTab !== 2} p={1.5}>
					<Carousel responsive={responsive.one} partialVisbile={true} infinite={true}>
						{arbeitgeber.job_offers.map((job, agi) => {
							return <JobCard key={'arbeitgeber-card-' + agi + '-' + job.id} job={job} variant={'coloredBorder'} hideImage={true} hideAccount={true} />
						})}
					</Carousel>
				</Box>


				</Box>
			</Card>
		</Popover>
	</Box>

}