import { Box, Chip, Container, Divider, Grid, Toolbar, Typography } from "@mui/material";
import { XBlock, XMasonry } from "react-xmasonry";
import JobCard from "../components/JobCard";
import { useEffect, useState } from "react";
import { AiOutlineRight } from "react-icons/ai";
import useMoreJobs from "../hooks/useMoreJobs";
import useJobs from "../hooks/useJobs";
import Loader from "../components/Loader";
import { useParams, useSearchParams } from "react-router-dom";

export default function FullView(props) {
	const { section } = useParams();

	const { moreJobs, isFetchingJobs, fetchMoreJobs, applyFilters } = useMoreJobs();
	const { jobzone, isFetchingJobzone, fetchJobzone } = useJobs();

	useEffect(() => {
		if(section)
			applyFilters({
				...moreJobs?.appliedFilters,
				jobzone_cluster: section
			})
	}, [section]);




	return <Box>
		<Box mb={0} sx={{
			backgroundImage: `url("/backgrounds/Hintergrund.jpg")`,
			backgroundSize: 'cover',
			backgroundPosition: 'center',
		}}>
			<Box sx={{
				pt: 10,
				backgroundImage: `url("/jobzone.svg")`,
				backgroundSize: 'contain',
				backgroundRepeat: 'no-repeat',
				backgroundPosition: 'right',
			}}>
			<Container>
				{isFetchingJobzone ? <Loader /> : <Grid container spacing={2}>
					<Grid item xs={12} md={4}>
						<img src={
							Array.isArray(moreJobs?.appliedFilters?.jobzone_cluster) && moreJobs?.appliedFilters?.jobzone_cluster?.length > 0 ?
								jobzone.sections.find((value) => {
									return value.key == moreJobs?.appliedFilters?.jobzone_cluster[0];
								})?.persons[0] : '/lady_2.png'
						}
							style={{ width: '100%', height: 'auto', maxHeight: 400, display: 'block', objectFit: 'contain' }} />
					</Grid>
					<Grid item xs={12} md={8}>
						<Box height={'100%'} display={'flex'} flexDirection={'column'} justifyContent={'flex-end'}>
							<Box my={2}>
								<Box className="text-cutout">
									{Array.isArray(moreJobs?.appliedFilters?.jobzone_cluster) ?
										jobzone.sections.find((value) => {
											return value.key == moreJobs?.appliedFilters?.jobzone_cluster[0];
										})?.titles[0]?.title : 'Eintönig'}
								</Box>
							</Box>

							<Typography variant="h3" fontWeight={'bold'} color={'white'} textTransform={"uppercase"} gutterBottom>{Array.isArray(moreJobs?.appliedFilters?.jobzone_cluster) ? 'Gibt es bei uns!' : 'Gibt es bei uns nicht'}</Typography>
						</Box>
					</Grid>
				</Grid>}
			</Container>
			</Box>
		</Box>
		<Toolbar sx={{mb:5,bgcolor:'#2a0240',color:'#fff'}}>
			#JOBZONE <AiOutlineRight size={12} /> Alle Jobs
		</Toolbar>
		<Container>
			<Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} mb={5}>
				<Box>
					<Typography variant="h4" gutterBottom>
						{moreJobs?.name}
					</Typography>
					
					{moreJobs?.availableFilters?.jobzone_cluster ? Object.keys(moreJobs?.availableFilters?.jobzone_cluster).map((key, i) => {
						let cluster = moreJobs?.availableFilters?.jobzone_cluster[key];
						return <Chip 
							color={moreJobs?.appliedFilters?.jobzone_cluster == key ? 'primary' : 'default'}
							sx={{m:.1}} 
							size="small" key={'cluster-' + i} label={cluster} 
							onClick={() => {
								let newFilters = {
									...moreJobs?.appliedFilters
								}

								if(newFilters?.jobzone_cluster == key) {
									delete newFilters.jobzone_cluster;
								} else {
									newFilters.jobzone_cluster = key;
								}
								applyFilters(newFilters)
							}} />
					}) : null}

					<Divider  sx={{my:1}} />

					{['hauptschule', 'realschule', 'abitur'].map((key, i) => {
						return <Chip 
							color={moreJobs?.appliedFilters?.recommended_abschluss == key ? 'primary' : 'default'}
							sx={{m:.1}} size="small" key={'cluster-abschluss-' + i} label={key} 
							onClick={() => {
								let newFilters = {
									...moreJobs?.appliedFilters
								
								}

								if(newFilters?.recommended_abschluss == key) {
									delete newFilters.recommended_abschluss;
								} else {
									newFilters.recommended_abschluss = key;
								}

								applyFilters(newFilters)
							}} />
					})}
				</Box>
				<Box>
				</Box>
			</Box>
		</Container>
		{isFetchingJobs ? <Loader /> : <XMasonry>
			{moreJobs?.paginated?.data && moreJobs?.paginated?.data?.map((job, index) => {
				return <XBlock key={'job-' + index}>
					<Box mb={2}>
						<JobCard job={job} />
					</Box>
				</XBlock>
			})}
		</XMasonry>}
	</Box>
}