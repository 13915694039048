const defaultConstants = {
	BASE_APP_URL: '/stellenmarkt/',
    BASE_URL:		'/stellenmarkt/',
    BASE_MEDIA_URL:	'https://shik-media.bytt.app/',
	BASE_XHR_URL:	'https://shik-engine.bytt.app/',
	SHORT_TIPPS: [
			{
			  "Titel": "Erfolgsgeheimnis im Praktikum",
			  "Beschreibung": "Sammle wertvolle Erfahrungen in Praktika, um Einblicke in verschiedene Branchen zu erhalten."
			},
			{
			  "Titel": "Soft Skills pflegen",
			  "Beschreibung": "Entwickle Soft Skills wie Kommunikation, Teamarbeit und Problemlösungsfähigkeiten, die in jedem Beruf gefragt sind."
			},
			{
			  "Titel": "Mentoren suchen",
			  "Beschreibung": "Finde erfahrene Mentoren, die dich bei deinem beruflichen Wachstum unterstützen und leiten können."
			},
			{
			  "Titel": "Finanzielle Planung",
			  "Beschreibung": "Lerne, mit Geld umzugehen und erstelle einen langfristigen finanziellen Plan."
			},
			{
			  "Titel": "Berufliche Passion entdecken",
			  "Beschreibung": "Entdecke deine Leidenschaft und finde einen Beruf, der wirklich zu dir passt."
			},
			{
			  "Titel": "Stressbewältigung",
			  "Beschreibung": "Lerne Techniken zur Stressbewältigung, um in anspruchsvollen Situationen ruhig und fokussiert zu bleiben."
			},
			{
			  "Titel": "Ehrenamtliches Engagement",
			  "Beschreibung": "Engagiere dich ehrenamtlich, um wertvolle Fähigkeiten zu entwickeln und Gutes zu tun."
			},
			{
			  "Titel": "Diversifiziere deine Fähigkeiten",
			  "Beschreibung": "Erweitere dein Fähigkeitsspektrum, um vielseitiger und attraktiver für Arbeitgeber zu sein."
			},
			{
			  "Titel": "Globales Denken",
			  "Beschreibung": "Denke global und entwickle interkulturelle Kompetenzen, um in einer vernetzten Welt erfolgreich zu sein."
			},
			{
			  "Titel": "Berufliches Netzwerk pflegen",
			  "Beschreibung": "Pflege dein berufliches Netzwerk kontinuierlich, um von wertvollen Kontakten zu profitieren."
			},		  
			{
			   "Titel": "Beeindruckender Lebenslauf",
			   "Beschreibung": "Erstelle einen beeindruckenden Lebenslauf, der deine Fähigkeiten und Erfahrungen hervorhebt und Arbeitgeber überzeugt."
			},
			{
			   "Titel": "Persönliches Anschreiben",
			   "Beschreibung": "Verfasse ein persönliches Anschreiben, das deine Leidenschaft für die Position und das Unternehmen zeigt."
			},
			{
			   "Titel": "Online-Präsenz stärken",
			   "Beschreibung": "Stärke deine Online-Präsenz, um in der digitalen Welt sichtbar zu sein und berufliche Kontakte zu knüpfen."
			},
			{
			   "Titel": "Vorbereitung auf Interviews",
			   "Beschreibung": "Bereite dich gründlich auf Vorstellungsgespräche vor, um selbstbewusst und kompetent aufzutreten."
			},
			{
			   "Titel": "Dankeschön-Nachricht",
			   "Beschreibung": "Sende eine herzliche Dankeschön-Nachricht nach dem Vorstellungsgespräch, um deine Wertschätzung zu zeigen."
			},
			{
			   "Titel": "Networking-Meister",
			   "Beschreibung": "Werde zum Networking-Meister und baue wertvolle berufliche Beziehungen auf, die deine Karriere vorantreiben."
			},
			{
			   "Titel": "Weiterbildungschampion",
			   "Beschreibung": "Werde ein Champion der beruflichen Weiterbildung und halte deine Fähigkeiten auf dem neuesten Stand."
			},
			{
			   "Titel": "Flexibilität als Stärke",
			   "Beschreibung": "Nutze deine Flexibilität und Anpassungsfähigkeit als Stärke, um in einer sich wandelnden Arbeitswelt erfolgreich zu sein."
			},
			{
			   "Titel": "Klarheit in den Zielen",
			   "Beschreibung": "Setze klare berufliche Ziele und arbeite gezielt auf sie hin, um deinen Traumjob zu erreichen."
			},
			{
			   "Titel": "Selbstvermarktungsprofi",
			   "Beschreibung": "Werde zum Profi der Selbstvermarktung und überzeuge Arbeitgeber von deinen einzigartigen Fähigkeiten und Qualitäten."
			},
			{
				"Titel": "Storytelling im Lebenslauf",
				"Beschreibung": "Verwandle deinen Lebenslauf in eine packende Geschichte, die deine berufliche Reise und Erfahrungen erzählt."
			 },
			 {
				"Titel": "Anschreiben, das begeistert",
				"Beschreibung": "Verfasse ein Anschreiben, das Arbeitgeber begeistert und deine Leidenschaft für die angestrebte Position unterstreicht."
			 },
			 {
				"Titel": "Online-Präsenz stärken",
				"Beschreibung": "Stärke deine Online-Präsenz und nutze soziale Medien, um berufliche Beziehungen zu knüpfen und sichtbar zu sein."
			 },
			 {
				"Titel": "Interview-Vorbereitung der Extraklasse",
				"Beschreibung": "Bereite dich auf Interviews vor, als wäre es ein Oscar-Gewinnerauftritt, um Arbeitgeber zu beeindrucken."
			 },
			 {
				"Titel": "Magischer Dankeschön-Trick",
				"Beschreibung": "Verwende den magischen Dankeschön-Trick nach Vorstellungsgesprächen, um im Gedächtnis zu bleiben und zu überzeugen."
			 },
			 {
				"Titel": "Networking als Superpower",
				"Beschreibung": "Entfalte deine Networking-Superpower und baue Beziehungen auf, die deine Karriere auf das nächste Level heben."
			 },
			 {
				"Titel": "Meister der Weiterbildung",
				"Beschreibung": "Werde zum Meister der beruflichen Weiterbildung und halte deine Fähigkeiten und Kenntnisse stets auf dem neuesten Stand."
			 },
			 {
				"Titel": "Anpassungsfähigkeit rocken",
				"Beschreibung": "Rocke die Anpassungsfähigkeit als Schlüsselqualifikation, um flexibel auf die sich wandelnde Arbeitswelt zu reagieren."
			 },
			 {
				"Titel": "Traumjobziel klar vor Augen",
				"Beschreibung": "Halte dein Traumjobziel klar vor Augen und arbeite mit gezielten Schritten darauf hin, um es zu erreichen."
			 },
			 {
				"Titel": "Deine Einzigartigkeit vermarkten",
				"Beschreibung": "Vermarkte deine Einzigartigkeit und zeige Arbeitgebern, was dich von anderen Bewerbern abhebt und begeistert."
			 },
			{
				"Titel": "Selbstständigkeit erwägen",
				"Beschreibung": "Überlege, ob die Gründung eines eigenen Unternehmens zu deinen beruflichen Zielen passt."
			},
			{
				"Titel": "Präsentationsfähigkeiten verbessern",
				"Beschreibung": "Arbeite an deinen Präsentationsfähigkeiten, um in Meetings und Präsentationen zu glänzen."
			},
			{
				"Titel": "Zeitmanagement meistern",
				"Beschreibung": "Entwickle effektive Zeitmanagement-Fähigkeiten, um produktiver und stressfrei zu arbeiten."
			},
			{
				"Titel": "Fremdsprachenkenntnisse erweitern",
				"Beschreibung": "Investiere in das Erlernen von Fremdsprachen, um deine beruflichen Möglichkeiten zu erweitern."
			},
			{
				"Titel": "Online-Kurse nutzen",
				"Beschreibung": "Nutze Online-Kurse und Bildungsplattformen, um neue Fähigkeiten und Zertifikate zu erwerben."
			},
			{
				"Titel": "Fehler als Lernchancen sehen",
				"Beschreibung": "Betrachte Fehler als Lernchancen und wachse durch Rückschläge und Herausforderungen."
			},
			{
				"Titel": "Arbeits-Lebensbalance finden",
				"Beschreibung": "Finde eine gesunde Balance zwischen Arbeit und Leben, um langfristig erfolgreich und glücklich zu sein."
			},
			{
				"Titel": "Projektmanagement beherrschen",
				"Beschreibung": "Erlange Kenntnisse im Projektmanagement, um komplexe Aufgaben effizient zu organisieren."
			},
			{
				"Titel": "Digitale Etikette wahren",
				"Beschreibung": "Bewege dich sicher in der digitalen Welt und achte auf angemessene Kommunikation und Datenschutz."
			},
			{
				"Titel": "Berufliche Werte definieren",
				"Beschreibung": "Kläre deine beruflichen Werte und finde Arbeit, die mit deinen Überzeugungen im Einklang steht."
			}			  

	],
}


let tempConstantsHolder = {}
if(window.b313?.c) {
	tempConstantsHolder = window.b313?.c;
}


const constants = {...defaultConstants, ...tempConstantsHolder}

export default constants