import axios from "axios";
import { useEffect, useState } from "react";
import constants from "./useConstants";
import useLocalStorage from "./useLocalStorage";


export default function useJobs() {
	const [jobzone, setJobzone] = useLocalStorage('jobzone', null);
	const [isFetchingJobzone, setIsFetching] = useState(jobzone === null ? true : false);


	function fetchJobzone() {
		
		setIsFetching(true);
		axios.get(`${constants.BASE_XHR_URL}api/v1/jobs/jobzone`)
		.then(response => {
			setJobzone({
				...response.data,
				__fetchedAt: new Date()
			});
		})
		.finally(() => {
			setIsFetching(false);
		});
	}

	useEffect(() => {						// valid for 1 minute
		if(!jobzone || jobzone.__fetchedAt < new Date(new Date().getTime() - 60 * 1000)) {
			setJobzone(null);
			fetchJobzone();
		}
	}, []);

	return {
		jobzone,
		isFetchingJobzone,
		fetchJobzone
	}
}