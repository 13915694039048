
import { Box, Divider, Typography } from "@mui/material";
import { AiOutlineLoading } from "react-icons/ai";


export default function LoaderInitial(props) {

	return <Box sx={{
		background: 'url(/backgrounds/Hintergrund_3.jpg)',
		backgroundSize: 'cover',
		backgroundPosition: 'center',
		minHeight: '100vh',
	}}>
		<Box  sx={{
			backgroundImage: 'url(/female-alle-trim.png)',
			backgroundRepeat: 'no-repeat',
			backgroundPosition: 'bottom center',
			backgroundSize: 'contain',
			aspectRatio: '1608 / 863',
		}}
			width={'100%'}
			display={'flex'}
			alignItems={'center'}
			justifyContent={'end'}
			flexDirection={'column'}
			maxHeight={863}
		>
		

			<Box className="text-cutout-container glow-unr animate" mb={5} mt={20} py={2} pt={1.5}>
				<Box display={'flex'} alignItems={'center'} justifyContent={'center'} gap={2}>
					<AiOutlineLoading  className="spin" color="#fff" size={69}/>
					<Typography variant="inherit" component={'div'} fontSize={{
					xs: 30,
					sm: 40,
					md: 60,
					lg: 70
				}} lineHeight={1} ><Box component={'span'}  className="text-cutout-w">JOBZONE wird geladen</Box></Typography>
				</Box>
			</Box>
		</Box>
	</Box>
}