import { Box, Button, Chip, Grid, List, ListItem, ListItemButton, ListItemText, Popover, Typography } from '@mui/material';
import { TypeAnimation } from 'react-type-animation';
import useMoreJobs from '../hooks/useMoreJobs';
import { useEffect, useMemo, useState } from 'react';
import useJobs from '../hooks/useJobs';
import constants from '../hooks/useConstants';
import MixLink from './MixLink';

export default function JobzoneHelperNavigator(props) {
	const { moreJobs } = useMoreJobs();
	const { jobzone, isFetchingJobzone, fetchJobzone } = useJobs();
	const [popoverOpen , setPopoverOpen] = useState(false);
	const [popoverRef , setPopoverRef] = useState(null);
	const [selectedSection, setSelectedSectionReal] = useState(null);

	function handlePopoverOpen(event) {
		setPopoverOpen(true);
		setPopoverRef(event.currentTarget);
	}

	function setSelectedSection(section) {
		setSelectedSectionReal(section);
	}

	const selectedSectionMem = useMemo(() => {
		if(!selectedSection) return null;
		let selectedZone = jobzone?.sections?.find((value) => {
			return value.id === selectedSection;
		})

		selectedZone.selectedPerson = selectedZone.persons[Math.floor(Math.random() * selectedZone.persons.length)];
		selectedZone.title = selectedZone.titles[Math.floor(Math.random() * selectedZone.titles.length)].subheader;

		return selectedZone;

	}, [selectedSection]);
	
	return <Grid container sx={{
		height: '100%'
	}}>
		<Grid item xs={12} md={5} lg={4} xl={3}>
			<Box height={'100%'} display={'flex'} alignItems={'end'} justifyContent={'center'} sx={{
				backgroundImage: `url("${selectedSectionMem?.selectedPerson || "/female-finance-3.png"}")`,
				backgroundSize: 'contain',
				backgroundPosition: 'bottom right',
				backgroundRepeat: 'no-repeat',
				minHeight: 300
			}}>
			</Box>
		</Grid>
		<Grid item xs={12} md={7} lg={8} xl={9}>
			<Box height={'100%'} display={'flex'} flexDirection={'column'} justifyContent={'space-between'} px={3}>
				<Box pb={3} pr={3} flexGrow={1}>
					<Box bgcolor={'#2a0240'} color={'#fff'} my={1.5} display={'inline-block'} py={1} px={2} fontSize={20} fontWeight={'bold'}>
						#JOBZONEHELFER
					</Box>
					<Box>
					<Box bgcolor={'#2a0240'} color={'#fff'} my={1.5} display={'inline-block'} py={.7} px={1.3} fontSize={17} fontWeight={'bold'}>
						{selectedSectionMem ? selectedSectionMem?.name : 'Treffe deine Wahl' }
					</Box>
					</Box>
					<Box minHeight={150} whiteSpace={'pre-line'}>
					<TypeAnimation
						key={'type-hello-' + selectedSectionMem?.title}
						style={{ fontSize: 17, fontWeight: 'bold' }}
						speed={33}
						cursor={false}
						sequence={[
							selectedSectionMem?.title || 'Hallo 👋! '+ "\nWir sehen du weißt schon genau, wohin du in der hin Zukunft willst. Dann beginne deine Reise." +
							"Willkommen zur Online-Ausbildungsmesse! Wir sind hier, um dir bei deinen beruflichen Zielen zu helfen. Wähle ein Berufsfeld aus, um weiterzumachen."
						]}></TypeAnimation>

					</Box>
						
				</Box>
				<Box my={2}>
							
							<Button 
								onClick={handlePopoverOpen}
								size="large"
								disableElevation
								className={selectedSectionMem ? '' : 'glow'}
								variant={selectedSectionMem ? "outlined" : "contained"}>
								Berufsfeld wählen
							</Button>

							{selectedSectionMem ? <Button
								disableElevation
								href={'#' + selectedSectionMem?.key}
								className={selectedSectionMem ? 'glow' : ''}
								size="large"
								variant="contained"
								color="primary"
								sx={{
									ml: 2
								}}
							>
								Mehr erfahren
							</Button> : null}

							<Popover
								open={popoverOpen}
								anchorEl={popoverRef}
								onClose={() => {
									setPopoverOpen(false);
								}}
							>
								<Box p={2} sx={{}}>
									<Typography variant="h4" gutterBottom>Berufsfeld wählen</Typography>
								<Box display={'grid'} gridTemplateColumns={{
									xs: '1fr',
									md: 'repeat(3, 1fr)'
								}} gap={2}>
									{jobzone?.sections?.map((value, index) => {

										return <Box className={'radial-hover'}><Box onClick={(e) => {
												setSelectedSection(value.id);
												setPopoverOpen(false);
											}}
											
										 	key={value.key} height={300} display={'flex'} justifyContent={'end'} flexDirection={'column'}  alignItems={'center'}
											sx={{
												backgroundImage: `url(${value.persons[0] ? (
													`${value.persons[0]}`
												) : ''})`,
												backgroundRepeat: 'no-repeat',
												backgroundPosition: 'bottom center',
												backgroundSize: 'contain',
												cursor: 'pointer',
											}}>
												<Box className="text-cutout-container" mb={1}>
													<Box className="text-cutout-w" fontSize={15}>
													{value.name}
													</Box>
												</Box>
											
										</Box></Box>
										
									})}
								</Box>
								</Box>
							</Popover>
						</Box>
			</Box>
		</Grid>
	</Grid>
}