import { AppBar, Avatar, Badge, Box, Button, Chip, Container, Dialog, DialogContent, DialogTitle, Divider, Grid, Link, List, ListItem, ListItemAvatar, ListItemText, TextField, Toolbar, Tooltip, Typography, useScrollTrigger } from "@mui/material";
import TopArbeitgeber from "./components/TopArbeitgeber";
import { FaCheckCircle, FaMapMarker } from "react-icons/fa";
import Carousel from "react-multi-carousel";
import { useEffect, useState } from "react";
import useResponsive from "./hooks/useResponsive";
import JobCard from "./components/JobCard";
import { Route, Router, Routes, useNavigate } from "react-router-dom";
import constants from "./hooks/useConstants";
import Skeleton from "react-loading-skeleton";
import { LiaMapMarkerSolid } from "react-icons/lia";
import Startseite from "./pages/Startseite";
import FullView from "./pages/FullView";
import { GiAlliedStar } from "react-icons/gi";
import JobDetail from "./pages/JobDetail";


import './App.css'
import { AiFillCiCircle, AiOutlineLeft } from "react-icons/ai";
import MixLink from "./components/MixLink";
import HeaderBar from "./components/HeaderBar";


function App() {
	const navigate = useNavigate();
	const [trigger, setIsTriggered] = useState(false);
	const scrollTrigger = useScrollTrigger({
		disableHysteresis: true,
		threshold: 0,
		target: window ? window : undefined,
	  });

	  useEffect(() => {
		if(scrollTrigger) {
			setIsTriggered(true);
		} else {
			setIsTriggered(false);
		}
	}, [scrollTrigger]);

	

  return (
    <Box>
		<HeaderBar />
			<Routes>
				<Route path={"*"} element={<Startseite />} />
				<Route path="/alle" element={<FullView />} />
				<Route path="/alle/:section" element={<FullView />} />
			</Routes>

		<Routes>
			<Route path="/job/:slug" element={<JobDetail />} />
		</Routes>
	</Box>
  );
}

export default App;
