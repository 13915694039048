import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import useResponsive from "../hooks/useResponsive";
import constants from "../hooks/useConstants";
import TopArbeitgeberProfil from "./TopArbeitgeberProfil";

export default function TopArbeitgeber(props) {
	const { arbeitgeber, sectionKey = null, hideKey = false, height = 250 } = props;
	console.log('arbeitgeber', arbeitgeber);
	const arbeitgeberCluster = arbeitgeber.cluster;
	const arbeitgeberIdObject = arbeitgeber.list;

	let lastKey = false;

	const [topArbeitgeber, setTopArbeitgeber] = useState(window.topArbeitgeber);
	const responsive = useResponsive();





	return (
		<Box>
			<Carousel responsive={responsive.small} partialVisbile={true} infinite={true}>
				{Object.keys(arbeitgeberCluster).map((key) => {
					if(sectionKey && key != sectionKey) return null;

					const arbeitgeberArray = arbeitgeberCluster[key];

					let isKeyDifferent = true;

					
					
					return arbeitgeberArray.map((arbeitgeberKey) => {
						if(lastKey === false || lastKey !== key) {
							isKeyDifferent = true;
						} else {
							isKeyDifferent = false;
						}
						lastKey = key;

								const arbeitgeber = arbeitgeberIdObject[arbeitgeberKey];
								return <Box key={key} height={'100%'} display={'flex'} flexDirection={'column'} justifyContent={'space-between'} px={2}>
									{!hideKey ? <Box height={20}>
										<Typography variant="caption" fontWeight={'bold'}>{isKeyDifferent ? key : null}</Typography>
									</Box>  :null}
									<Box display={'flex'} justifyContent={'center'} flexWrap={'wrap'} gap={2} flexGrow={1}>
										<TopArbeitgeberProfil arbeitgeber={arbeitgeber} height={height} />
									</Box>
								</Box>
					})
				})}
			</Carousel>
		</Box>
	)
}