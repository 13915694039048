import React, { useMemo } from "react"
import { Box, Container, Grid, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import Carousel from "react-multi-carousel"
import useResponsive from "../../hooks/useResponsive"
import MixLink from "../MixLink"



export default function HeroSection(props) {
	const { jobzone } = props;

	const responsive = useResponsive();
	const [ random ] = useState(Math.random());


	const allePersonen = useMemo(() => {
		if(!jobzone?.sections) return null;

		let personen = [];
		jobzone?.sections?.map((sektion, index) => {
			let currentPersonen = sektion.persons;
			currentPersonen = currentPersonen.map((person, index) => {
				personen.push({
					key: sektion.key,
					name: sektion.titles[Math.floor(random * sektion.titles.length)].title,
					value: person
				});
				return;
			});
		});
		return {
			random: personen[Math.floor(random * personen.length)],
			alle: personen
		};
	}, [jobzone]);

	const randomizedSections = useMemo(() => {
		if(!jobzone || !jobzone.sections) return null;
		let randomSections = [];
		jobzone?.sections.map((value, index) => {
			randomSections.push(value);
		});
		randomSections.sort(() => Math.random() - 0.5);
		return randomSections;
	}, [jobzone]);


	return <Box mb={0} sx={{
		backgroundImage: `url("/backgrounds/Hintergrund.jpg")`,
		backgroundSize: 'cover',
		backgroundPosition: 'center',
	}}>
		<Box sx={{
			pt: 10,
			backgroundImage: `url("/jobzone.svg")`,
			backgroundSize: 'contain',
			backgroundRepeat: 'no-repeat',
			backgroundPosition: 'right',
		}}>
		<Container>
			<Grid container spacing={2}>
				<Grid item xs={12} md={7}>
					<Box height={'100%'} display={'flex'} flexDirection={'column'} justifyContent={'space-between'}>
						<Box>

						</Box>
						<Box>
							<Box my={2}>
								<img src="/eintoenig.svg" style={{ width: 'auto', height: '70px', display: 'block' }} />
							</Box>

							<Typography variant="h3" fontWeight={'bold'} color={'white'} textTransform={"uppercase"}  maxWidth={600}>
								gibt es bei uns nicht
							</Typography>
							<Typography variant="h3" fontWeight={'bold'} color={'white'} textTransform={"uppercase"} gutterBottom  maxWidth={600}>
								<MixLink href={'/alle'} color="#fff">
									Alle Stellen
								</MixLink>
							</Typography>
						</Box>
					</Box>
				</Grid>
				<Grid item xs={12} md={5}>
					{/* <pre>
						{JSON.stringify(allePersonen.alle, null, 2)}
					</pre> */}
					<Carousel 
						style={{
							height: '100%',
							minHeight: 500,
							with: '100%',
						}}
						
						responsive={responsive.single} partialVisbile={true} infinite={true} autoPlay={true} autoPlaySpeed={10000} pauseOnHover removeArrowOnDeviceType={['all']}>
					<Box
						sx={{
							backgroundImage: `url("${allePersonen?.random?.value}")`,
							backgroundSize: 'contain',
							backgroundRepeat: 'no-repeat',
							backgroundPosition: 'bottom center',
							minHeight: 500,
							height: '100%',
						}}
					>
						<Box height={'100%'} display={'flex'} alignItems={'center'} justifyContent={'flex-end'} flexDirection={'column'}>
							<Box className="text-cutout-container glow-unr hoverable" mb={3}
								sx={{
									cursor: 'pointer'
								}}
							onClick={() => {
								document.getElementById(allePersonen?.random?.key)?.scrollIntoView({
									behavior: 'smooth'
								})
							}}>
								<Box className="text-cutout-w" fontSize={30}>
									{allePersonen?.random?.name}
								</Box>
							</Box>
						</Box>
					</Box>
					{randomizedSections?.map((sektion, index) => {
						if(sektion.key == allePersonen?.random?.key) return null;

						let randomPerson = sektion.persons[Math.floor(random * sektion.persons.length)];
						return <Box
							sx={{
								backgroundImage: `url("${randomPerson}")`,
								backgroundSize: 'contain',
								backgroundRepeat: 'no-repeat',
								backgroundPosition: 'bottom center',
								minHeight: 500,
								height: '100%',
							}}
						>
						<Box height={'100%'} display={'flex'} alignItems={'center'} justifyContent={'flex-end'} flexDirection={'column'}>

								<Box className="text-cutout-container glow-unr hoverable" mb={3} 
								onClick={() => {
									document.getElementById(sektion.key)?.scrollIntoView({
										behavior: 'smooth'
									})
								}}
								sx={{
									cursor: 'pointer'
								
								}}>
									<Box className="text-cutout-w" fontSize={30}>
										{sektion.titles[0].title}
									</Box>
								</Box>
							</Box>

						</Box>
					})}
					</Carousel>
				</Grid>
			</Grid>
		</Container>
		</Box>
	</Box>
}